<template>
    <div class="mxw-con">

        <div class="mxw-js">
            <div class="js-top">
                <img src="/imgs/js1.png" alt="">
                <img src="/imgs/js2.png" alt="">
                <img src="/imgs/js3.png" alt="">
            </div>
            <div class="js-b">
                <p>APP介绍：商会动态—发布潍坊商会成员新闻（动态）并且涵盖动态评价，为在线用户提供免费交流平台。动态中也包括好物推荐。</p>
                <p>咨询服务—为会员提供如各医疗卫生、教育、法律、科技、金融、部队、政策等领域专家引荐。为老乡提供高端导医服务、就业指导、婚介服务等有偿和无偿咨询服务。</p>
                <p>个人中心—为会员提供互动平台，可向其他会员提供服务。并且建立商会自持购物商城（商会甄选），筛选优秀产品并且提供优惠价格，让会员放心省钱买好物。</p>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: "ag",
    components: {
    },
    data() {
        return {
            href: "index.html",
        };
    },
    methods() {
        document.title = "灵素斋";
    },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.mxw-con {
    width: 100%;
    background-image: linear-gradient(109.6deg, rgb(0, 51, 102) 11.2%, rgb(187, 187, 187) 91.1%);

    .mxw-js {
        width: 70%;
        margin: 35px auto;
       padding: 35px 0;
       box-sizing: border-box;
        // background-color: #000;
        .js-top {
            width: 100%;
            display: flex;
            justify-content: space-between;
            img {
                // width: 300px;
                height: 600px;
                margin: 0 15px;
            }
        }

        .js-b {
            width: 100%;
            text-align: center;
            padding-top: 15px;
            box-sizing: border-box;
            p {
                font-size: 14px;
                margin: 25px 0;
                color: #fff;
                
            }
        }
    }
}
</style>